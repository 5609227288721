import Log from '../utils/logger.js';
import { BaseLoader, LoaderStatus, LoaderErrors } from './loader.js';
import { RuntimeException } from '../utils/exception.js';
// @ts-ignore
import { StreamrClient } from '@streamr/sdk';
import { trace } from "firebase/performance";
import { logEvent } from "firebase/analytics"
const _ = require("lodash");

// For MPEG-TS/FLV over Streamr Protocol live stream
class CustomLoader extends BaseLoader {

    static isSupported() {
        return true
    }

    constructor() {
        super('streamr-loader');
        this.TAG = 'StreamrLoader';

        this._needStash = true;
        this._streamrClient = null;
        this._requestAbort = false;
        this._receivedLength = 0;
        this._client = null
        this._msgNumbers = []
        this._msgNumber = null
        this._msgCounter = 0
        this._unpackedVideoDataReceivedInBytes = 0
        this._packetLatency = 0
    }

    destroy() {
        super.destroy();
    }

    base64ToArrayBuffer(base64) {
        const binaryString = atob(base64);
        const length = binaryString.length;
        const bytes = new Uint8Array(length);

        for (let i = 0; i < length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        return bytes.buffer;
    }

    calculateMean(arr) {
        const sum = arr.reduce((acc, val) => acc + val, 0);
        return sum / arr.length;
    }

    calculateStandardDeviation(arr, mean) {
        const squaredDiffs = arr.map(val => Math.pow(val - mean, 2));
        const meanOfSquaredDiffs = this.calculateMean(squaredDiffs);
        return Math.sqrt(meanOfSquaredDiffs);
    }

    removeOutliers(arr, threshold = 2) {
        const mean = this.calculateMean(arr);
        const standardDeviation = this.calculateStandardDeviation(arr, mean);

        return arr.filter(val => {
            const diff = Math.abs(val - mean);
            return diff <= threshold * standardDeviation;
        });
    }

    async measureRequestTime(url) {
        const startTime = new Date().getTime() // Record start time
        try {
            //send t0
            const response = await fetch(url + encodeURIComponent(startTime)); // Make the request
            const t3 = new Date().getTime(); // Record end time after the request completes
            //var nowTimeStamp = new Date().getTime();
            const data = await response.json();
            //response sent
            const t2 = data.sent
            //received t1-t0 as diff
            var serverClientRequestDiffTime = data.diff
            // NTP 
            var clockOffset = (serverClientRequestDiffTime + t2 - t3) / 2;
            return clockOffset
        } catch (error) {
            console.error('Request failed:', error);
        }
    }

    async estimateTimeDiff() {
        const timeUrl = "https://api.streamr.space/time?time="
        let measurements = []
        let offSet = 0
        for (let i = 0; i < 20; i++) {
            const result = await this.measureRequestTime(timeUrl)
            offSet = result
            measurements.push(result)
        }
        console.log('Offset: ', offSet)
        console.log('Measurements: ', measurements)
        console.log('Measurements without outliers: ', this.removeOutliers(measurements))
        const clockDifference = _.mean(measurements)
        const clockDifferenceWithoutOutliers = _.round(_.mean(this.removeOutliers(measurements)), 2)
        console.log('MEAN CLOCK OFFSET in ms: ', clockDifference)
        console.log('MEAN CLOCK OFFSET when outliers removed in ms: ', clockDifferenceWithoutOutliers)
        return clockDifference
    }

    //move time adjustment estimation to separate function

    async open(dataSource) {
        const clockDifference = await this.estimateTimeDiff()

        try {
            this._status = LoaderStatus.kConnecting;
            const { address, privateKey } = StreamrClient.generateEthereumAccount();
            let streamrClient = window.streamr = this._client = new StreamrClient({
                auth: {
                    privateKey: privateKey,
                },
                metrics: false,
                gapFill: true,
                contracts: {
                    ethereumNetwork: {
                        chainId: 137
                    },
                    rpcs: [{
                        url: "https://polygon.blockpi.network/v1/rpc/aa709026a89d4c7f6c84eef8ba0ada28d0de52a4",
                        timeout: 120000
                    }]
                },
            })

            let msgCounter = 0
            // Get the current URL
            const currentUrl = new URL(window.location.href)

            // Get the values of streamId and partitionId
            const streamId = currentUrl.searchParams.get('stream') == null ? 'streamr.eth/demos/video' : currentUrl.searchParams.get('stream')
            const partitionId = currentUrl.searchParams.get('partition') == null ? 0 : parseInt(currentUrl.searchParams.get('partition'))
            console.log('stream Id: ', streamId)
            console.log('partition Id: ', partitionId)
            //try to get uri parameters streamId & partition if given
            const timeToFirstMessage = _.once(function (time1, time2, self) {
                self._emitter.emit('subscribe_till_first_message', time1 - time2)
                self._emitter.emit('navigation_till_first_message', time1)
            })
            const startToSubscribeTimeStamp = performance.now() + clockDifference
            let previousMsgNumber = undefined
            let self = this
            streamrClient.getStream(streamId).then(() => { }) // populate cache BEFORE waiting for 1st message to arrive
            streamrClient.subscribe({ id: streamId, partition: partitionId }, (message) => {
                let unpackedVideoDataReceivedInBytes = 0
                // retrieve array of base64 encoded content from message
                // loop through array, decode base64, dispatch each package to arraybuffer
                const firstMessageReceivedTimeStamp = performance.now() + clockDifference

                timeToFirstMessage(firstMessageReceivedTimeStamp, startToSubscribeTimeStamp, self)



                message['b'][1]?.forEach((element) => {
                    let arrBuf = this.base64ToArrayBuffer(element)
                    unpackedVideoDataReceivedInBytes = unpackedVideoDataReceivedInBytes + arrBuf.byteLength
                    this._dispatchArrayBuffer(arrBuf)
                })
                self._msgCounter = self._msgCounter + 1;
                self._packetLatency = Date.now() + clockDifference - message['b'][2]
                self._unpackedVideoDataReceivedInBytes = unpackedVideoDataReceivedInBytes
                // check if message['b'][3] exists
                if (_.isNumber(message['b'][3])) {
                    const previousNumber = self._msgNumber
                    self._msgNumber = message['b'][3]
                    self._emitter.emit('msgNumber', self._msgNumber)

                    if (previousNumber != null && previousNumber + 1 < self._msgNumber) {
                        console.log('@@@@@@@@@ GAP DETECTED @@@@@@@@@')
                        console.log(` ${previousNumber} --- > ${self._msgNumber}`)
                    }
                }
                self._emitter.emit('msgCounter', self._msgCounter)
                self._emitter.emit('packetLatency', self._packetLatency)
                self._emitter.emit('unpackedVideoDataReceivedInBytes', self._unpackedVideoDataReceivedInBytes)
            })

        } catch (e) {
            console.log(e)
            this._status = LoaderStatus.kError;
        }
    }

    abort() {
        this._client = null
    }

    _dispatchArrayBuffer(arraybuffer) {
        let chunk = arraybuffer;
        let byteStart = this._receivedLength;
        this._receivedLength += chunk.byteLength;

        if (this._onDataArrival) {
            this._onDataArrival(chunk, byteStart, this._receivedLength);
        }
    }
}

export default CustomLoader;
